import React from 'react'
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/hero"
//import BlogRecommendation from "../components/blogRecommendation"
import BlogCategoryMenu from "../components/blogCategoryMenu"
import SvgImgSelector from "../components/svgImgSelector"
import { blogBGColor } from "../utils/variables"

const Blog =({ data }) => {
    const shortenData = data.allMarkdownRemark.edges
    return (
            <Layout page="Blog">
            <Seo title="Blog" 
                 description="【待ち時間０秒】の高速ウェブサイト制作専門のmonoteinのブログ。Jamstack、ヘッドレスCMSといった最新テクノロジーでサイトのスピードを高速化し、高SEO、高エンゲージメント、高CVのウェブサイトを実現します。"
            />
            <div className="blog">
              <PageHeader page="Blog"/>
              <div className="container">
                 {/*  <BlogRecommendation/> */}
                  <BlogCategoryMenu/>
                  <div className="grid-container">
                    {shortenData.map((x, index) => {
                      const { fakeData, date, title, path, tags } = x.node.frontmatter;
                      return (
                        <div className="grid-item" key={index}>
                            <Link to={path}>      
                                <div className="img-container">
  {/*                                   <SvgImgSelector index={index}/> */}
                                    <GatsbyImage image={x.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="xxxx"/>
                                </div>
                                <div className="text-container"> 
                                    <h3>{title}</h3>
                                    <p>【 { tags[0] } 】<span>{fakeData ? fakeData : date} </span></p>
                                </div> 
                            </Link> 
                        </div>
                      )
                    })}
                  </div>
              </div>
            </div>
        </Layout>
    );
};

export const blogIndex = graphql`
    query BlogPageQuery {
      allMarkdownRemark(
          sort: { fields: frontmatter___id, order: DESC }
      ) {
          edges {
            node {
              frontmatter {
                    id
                    path
                    fakeData
                    date
                    update
                    title
                    excerpt
                    description
                    tags
                    categoryTagJP
                    featuredImage {
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED, quality: 10)
                        }
                    }
            }
          }
        }
      }
    }
  `

export default Blog;